.container {
  padding: 4px 8px;
  border-radius: 20px;

  &.completed {
    border: 1px solid var(--color-neutral-5);

    > * {
      color: var(--color-success) !important;
    }
  }

  &.mandatory {
    background-color: var(--color-error);
    border: none;

    > * {
      color: var(--color-neutral-1) !important;
    }
  }

  &.primary {
    background-color: var(--color-primary-6);
    border: none;

    > * {
      color: var(--color-neutral-1) !important;
    }
  }
}
