.logoGsp5 {
  height: 22px;
  color: var(--color-primary-6);
  position: absolute;
  bottom: calc(4% + var(--safe-area-bottom));
  left: 50%;
  transform: translateX(-50%) translateY(-4%);
}

.logoBlue {
  height: 40px;
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translateX(-50%) translateY(-8%);
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  top: 48%;
  transform: translateY(-48%);
}

:global body.barcode-scanner-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;

  &:after {
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 40px;
    height: 40px;
    border: 4px solid white;
  }
}

:global {
  .ant-input-prefix {
    color: var(--color-character-secondary-45) !important;
  }

  .ant-form-item-label > label {
    font-size: 16px !important;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--color-character-title-85) !important;
  }
}


a {
  color: var(--color-primary-6);
  size: 14px;
  font-weight: 500;
}
