.wrapper {
  width: 80% !important;

  :global {
    .ant-modal-close {
      top: 10px;
      inset-inline-end: 10px;
    }

    .ant-modal-body {
      > :first-child {
        width: 64px;
        height: 64px;
      }
    }
  }
}
