.card {
  display: block;
  padding: 16px;
  border: 1px solid var(--color-neutral-4);
  border-radius: 20px;
  text-decoration: none !important;
  margin: 0 16px 16px 16px;
}

.withoutMargin {
  margin: 0;
}
